import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClaimService } from '../claim.service';
import { UserService } from '../loginCore/user.service';
import { Person } from '../person';
import { LoadingService } from '../loading.service';
import { UtilService } from '../util.service';
import { Claim } from '../claim';
import { Datebox } from '../datebox';
import { Countries } from '../countries';
import { Router } from '@angular/router';
import { MatDialog , MatDialogConfig} from "@angular/material/dialog";
import { ReadmoredialogComponent } from '../readmoredialog/readmoredialog.component';
import { ConfirmclaimdialogComponent } from '../confirmclaimdialog/confirmclaimdialog.component';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-submit-claim',
  templateUrl: './submit-claim.component.html',
  styleUrls: ['./submit-claim.component.css'],
  exportAs: 'ngForm'
})
export class SubmitClaimComponent implements OnInit {

  constructor(
    private claimService: ClaimService,
    public userService: UserService,
    public utilService: UtilService,
    private loadingService: LoadingService,
    public snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private config: NgbDatepickerConfig
  ) {
    // disable future date in datepicker
    const currentDate = new Date();
    config.maxDate = {year:currentDate.getFullYear(), month:currentDate.getMonth()+1, day: currentDate.getDate()};
    config.minDate = {year:currentDate.getFullYear()-1, month:currentDate.getMonth()+1, day: currentDate.getDate()};
    config.outsideDays = 'hidden';
  }

  ngOnInit() {
    this.getUserName();
  }

  submitted = false;
  pendingAttachment = false;

  disclaimerIsChecked = false;

  user: Object = new Object;
  //function to get username from user service
  getUserName(): void {
    this.userService.getCurrentUser().then(user => this.user = user);
  }

  systemMessage: string;

  isBackDateClaim: boolean = false;
  closingDate: Date;

  quotaYear: string;
  currentYear: string;
  lastYear: string;

  claimItemsList: string [];
  receiptDateBoxObject: Datebox;

  disableLoading: number = 0;

  countries = Countries;

  selectedCountry;
  // initially fill the selected country for further loop
  countryList = this.countries;

  claimableObjects = [
    { name: 'luggage', value: 'Luggage' },
    { name: 'winterCoats', value: 'Winter Coats or Jackets' },
    { name: 'sweaters', value: 'Sweaters or Thermal Pants' },
    { name: 'innerwear', value: 'Thermal Innerwear' },
    { name: 'scarves', value: 'Scarves' },
    { name: 'hats', value: 'Winter Hats' },
    { name: 'gloves', value: 'Gloves' }
  ];

  itemChange(event:any)
  {
      console.log(event.source.value, event.source.selected);
      //this.claim["claimItems"] = this.claim["claimItemsList"].map(x => event.source.value).join(",");
  }

  // open dialog box for read More button
  openDialog() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(ReadmoredialogComponent, dialogConfig);

  }

  // open dialog box for claim confirmation before Submit
  openConfirmClaimDialog() {

    const confirmDialogConfig = new MatDialogConfig();

    let date = this.receiptDateBoxObject;
    this.claim["receiptDate"] = date.year + "-" + date.month + "-" + date.day;
    this.claim["claimItems"] = this.claimItemsList.map(x => x).join(",");
    let formattedItems = this.claimService.formatClaimItem(this.claim["claimItems"]);
    this.claim["country"] = this.selectedCountry.name;

    confirmDialogConfig.disableClose = true;
    confirmDialogConfig.autoFocus = true;
    confirmDialogConfig.data = {
        claimDetail: this.claim,
        formattedItems: formattedItems
    };
    confirmDialogConfig.width = '600px';

    const confirmDialogRef = this.dialog.open(ConfirmclaimdialogComponent, confirmDialogConfig);

    confirmDialogRef.afterClosed().subscribe(
        data => {
            if (data !== undefined) {
              this.loadingService.enableLoading();
              this.submitClaim(data)
            }
        }
    );

  }

  // check whether disclaimer is checked, if yes, then pop up dialogbox for user to read
  checkDisclaimer(event: any) {
    if (event.checked == true) {
      this.openDialog();
    }
  }

  claim: Claim = new Claim();

  // create Claim Object and ready to submit to backend
  submitClaim(status: string) {

    if (this.submitted) {
      return false;
    }

    //let date = this.receiptDateBoxObject;

    //this.claim["receiptDate"] = date.year + "-" + date.month + "-" + date.day;
    this.claim["status"] = status;

    //this.claim["claimItems"] = this.claimItemsList.map(x => x).join(",");

    if (!(Number(this.claim["claimAmount"]) > 0)) {
      this.openAlertSnackbar("The claim amount has to be more than 0.");
      this.loadingService.disableLoading();
      return false;
    }

    // assign country to claim object
    //this.claim["country"] = this.selectedCountry.name;

    this.submitted = true;

    this.claimService.submitClaim(this.claim).subscribe(
      claim => {
        this.openSnackbar('Claim has been successfully saved. Please continue to attach your softcopy of receipts - <' + claim.claimId + '>');
        this.claimService.setClaimInformation(claim);
        this.disableLoading += 1;
        if (this.disableLoading == 1) this.loadingService.disableLoading();
        this.router.navigate(['/employeeClaimDetail']);
      },
      err => {
        if ((err.error).includes("Receipt No.") && (err.error).includes("is duplicated")) {
          this.openAlertSnackbar(err.error + ' To submit a new one, please cancel the previous claim with the same Receipt Number first.');
        } else {
          this.openAlertSnackbar('There is an error: ' + err.message); console.error('There was an error: ' + err.message);
        }

        this.loadingService.disableLoading();
        this.submitted = false;
      }
    );
  }

  openSnackbar(msg: string) {
    this.snackBar.open(msg, "Got it!",
      {
        duration: 3000
      });
  }

  openAlertSnackbar(msg: string) {
    this.snackBar.open(msg, "Dismiss",
      {
        duration: 10000,
        panelClass: ["snack-error"]
      });
  }

  onDateSelect(event: any) {
    console.log(event);
  }

  isQuotaYearCurrentYear() {
    return (this.quotaYear == this.currentYear);
  }

}
