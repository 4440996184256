// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    // Test Firebase
    apiKey: "AIzaSyDbUpkM4f-iLKF1q_9GnzX044IJU-x8frk",
    authDomain: "gcp-sqo-wclc-t.firebaseapp.com",
    projectId: "gcp-sqo-wclc-t",
    storageBucket: "gcp-sqo-wclc-t.appspot.com",
    messagingSenderId: "504465378675",
    /* apiURL: "https://core-dot-gcp-sqo-wclc-t.df.r.appspot.com/api/",
    nonApiURL: "https://core-dot-gcp-sqo-wclc-t.df.r.appspot.com/" */
    apiURL: "https://wclc-api-52.syensqo.com/api/",
    nonApiURL: "https://wclc-api-52.syensqo.com/"


  },
  production: false

};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
