import { Component, OnInit, ViewChild, ElementRef, Output, Optional } from '@angular/core';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ClaimService } from '../claim.service';
import { Claim } from '../claim';
import { UserService } from '../loginCore/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmployeeClaimdetailComponent } from '../employee-claimdetail/employee-claimdetail.component';
import { LoadingService } from '../loading.service';


@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.css']
})

export class FileUploadComponent implements OnInit {

    @ViewChild('myInput', {static: false})
    myInputVariable: ElementRef;

    ref: AngularFireStorageReference;
    task: AngularFireUploadTask;
    uploadState: Observable<string>;
    uploadProgress: Observable<number>;
    downloadURL: Observable<string>;
    path: string = 'tmp//';
    claimDetail: Claim;
    imageToShow: any;

    constructor(
        private afStorage: AngularFireStorage,
        private claimService: ClaimService,
        public userService: UserService,
        private loadingService: LoadingService,
        public snackBar: MatSnackBar,
        @Optional() private employeeClaimDetail?: EmployeeClaimdetailComponent
    ) {}

    ngOnInit() {
        if (this.claimService.getClaimInformation() === undefined) {
            this.claimDetail = JSON.parse(localStorage.getItem('claimObj'));
        } else {
            this.claimDetail = this.claimService.getClaimInformation();
            localStorage.setItem('claimObj', JSON.stringify(this.claimDetail));
        }
        this.userService.getHRRole();
    }

    isHRRole() {
        return this.userService.isHR;
    }

    upload(event) {
        const id = Math.random().toString(36).substring(2);
        this.ref = this.afStorage.ref(this.path + id);
        this.task = this.ref.put(event.target.files[0]);
        // get suffix of the uploaded file
        const suffix = event.target.files[0].name.split('.').pop();
        // allow user upload ony png, jpg and jpeg related file
        if (suffix.toLowerCase( ) === 'png' || suffix.toLowerCase( ) === 'jpg' || suffix.toLowerCase( ) === 'jpeg') {
            this.uploadProgress = this.task.percentageChanges();
            this.task.snapshotChanges().pipe(
                finalize(() => {
                    this.ref.getDownloadURL().subscribe(url => {
                        this.claimService.attachClaimReceipt(this.claimDetail.claimId, url)
                            .subscribe(claimResult => {
                                this.claimDetail = claimResult;
                                this.employeeClaimDetail.claimDetail = claimResult;
                                this.claimService.setClaimInformation(claimResult);
                                localStorage.setItem('claimObj', JSON.stringify(claimResult));
                                this.openSnackbar('Attachment has been uploaded successfully.');
                                console.log(this.myInputVariable.nativeElement.files);
                                this.myInputVariable.nativeElement.value = "";
                                console.log(this.myInputVariable.nativeElement.files);
                            })
                    });
                }))
                .subscribe();
        } else {
            this.openAlertSnackbar('Please upload attachment with only file with extention png, jpg or jpeg!');
        }

    }

    getAttachment(url) {
        const regex = /id=(\d+)/;
        // Use the regex to find and extract the id
        const match = url.match(regex);
        let id;
        // Check if a match was found and extract the id
        if (match) {
            id = match[1]; // The id is captured in the first capturing group
            console.log(id);
        } else {
            console.log("No id found in the input string.");
        }
        url = id;
        this.loadingService.enableLoading();
        this.claimService.getAttachment(url).subscribe(data => {
            const url = window.URL.createObjectURL(data);
            window.open(url);
            this.loadingService.disableLoading();
        }, error => {
            console.log(error);
            this.loadingService.disableLoading();
        });
    }

    delete(keyString) {
        this.loadingService.enableLoading();
        this.claimService.deleteAttachment(keyString).subscribe(
            claimResult => {
                this.claimDetail = claimResult;
                this.employeeClaimDetail.claimDetail = claimResult;
                this.claimService.setClaimInformation(claimResult);
                this.uploadProgress = undefined;
                localStorage.setItem('claimObj', JSON.stringify(claimResult));
                this.openSnackbar('Attachment has been deleted successfully.');
                this.loadingService.disableLoading();
            },
            error => {
                this.openAlertSnackbar('Error occured. Failed to delete the attachment. ' + error);
                this.loadingService.disableLoading();
            })
    }

    openSnackbar(msg: string) {
        this.snackBar.open(msg, "Got it!",
            {
                duration: 3000
            });
    }

    openAlertSnackbar(msg: string) {
        this.snackBar.open(msg, "Dismiss",
            {
                duration: 10000,
                panelClass: ["snack-error"]
            });
    }
}
