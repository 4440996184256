// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  margin: 0px 20px 0px 20px;
}

.row.odd{
  background-color:#d9dce0;
}
.row.even{
  background-color:#f7faff;
}

.reminder {
  font-size: 14px;
  color: blue;
}

.mat-list-base {
  padding-top: 0px;
  height: auto;
}

.mat-list-item {
  height: auto !important;
}

.applyPadding {
  padding: 0px 0px 0px 16px;
}

.col-md-3 {
  padding-right: inherit;
}


.mat-mdc-list-item, .mat-mdc-list-option {
  width: 100%;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  height: -moz-fit-content!important;
  height: fit-content!important;
}

.mdc-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.6) !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/confirmclaimdialog/confirmclaimdialog.component.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;;AAGA;EACE,WAAW;EACX,sBAAsB;EACtB,0CAA0C;EAC1C,kCAA6B;EAA7B,6BAA6B;AAC/B;;AAEA;EACE,SAAS;EACT,UAAU;EACV,qBAAqB;AACvB;;AAEA;EACE,oEAAoE;AACtE","sourcesContent":["button {\n  margin: 0px 20px 0px 20px;\n}\n\n.row.odd{\n  background-color:#d9dce0;\n}\n.row.even{\n  background-color:#f7faff;\n}\n\n.reminder {\n  font-size: 14px;\n  color: blue;\n}\n\n.mat-list-base {\n  padding-top: 0px;\n  height: auto;\n}\n\n.mat-list-item {\n  height: auto !important;\n}\n\n.applyPadding {\n  padding: 0px 0px 0px 16px;\n}\n\n.col-md-3 {\n  padding-right: inherit;\n}\n\n\n.mat-mdc-list-item, .mat-mdc-list-option {\n  width: 100%;\n  box-sizing: border-box;\n  -webkit-tap-highlight-color: rgba(0,0,0,0);\n  height: fit-content!important;\n}\n\n.mdc-list {\n  margin: 0;\n  padding: 0;\n  list-style-type: none;\n}\n\n.mat-mdc-list-base {\n  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.6) !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
